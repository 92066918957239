import React from 'react';
import { ApolloClientProvider } from 'cubedist-cube-pipeline-common-ui';
import { AuthProvider } from 'cubedist-cube-pipeline-common-ui';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getEnv } from './utils/environment';
import CascadingFiltersProvider from 'contexts/CascadingFilters.context';
import FeaturesProvider from 'contexts/Features.context';
import { LocalStorageProvider } from 'contexts/LocalStorageContext';
import NotificationProvider from 'contexts/Notification.context';
import RolesProvider from 'contexts/Roles.context';
import UserProfileProvider from 'contexts/UserProfile.context';
import ExternalAppsProvider from 'contexts/ExternalApps.context';
import Router from './Router';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const env = getEnv();

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: '323b4851-ed05-47eb-92e6-4282e9dde500',
    clientToken: 'pube4f299a815260c4461cdcc4a2b7631c1',
    site: 'datadoghq.com',
    service: 'cube-pipeline-ui',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });

  datadogRum.setGlobalContextProperty('account_id', '091975348311');
  datadogRum.startSessionReplayRecording();
}

const queryClient = new QueryClient();

root.render(
  <AuthProvider path="cube-pathname" search="cube-search">
    <ApolloClientProvider defaultClient="pipeline">
      <LocalStorageProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <FeaturesProvider>
              <RolesProvider>
                <NotificationProvider>
                  <ExternalAppsProvider>
                    <BrowserRouter>
                      <UserProfileProvider>
                        <CascadingFiltersProvider>
                          <Router />
                        </CascadingFiltersProvider>
                      </UserProfileProvider>
                    </BrowserRouter>
                  </ExternalAppsProvider>
                </NotificationProvider>
              </RolesProvider>
            </FeaturesProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </LocalStorageProvider>
    </ApolloClientProvider>
  </AuthProvider>,
);
