import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { filter, find, forOwn, get, isEmpty, groupBy } from 'lodash';
import { useCascadingFilterContext } from 'contexts/CascadingFilters.context';
import { useProfileContext } from 'contexts/UserProfile.context';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import CascadingFilters from 'components/CascadingFilters/CascadingFilters';
import { formatDisplayName, getLabelForChip, sortAsc } from 'components/CascadingFilters/utils';

const cascadingFiltersConfig = [
  {
    field: 'quickFilters',
    optionsPath: 'quickFilters',
    headerName: 'Quick Filters',
    type: 'CascadingFiltersSection',
    hideAllOption: true,
  },
  {
    field: 'distributorCode',
    optionsPath: 'distributor',
    headerName: 'Distributor',
    type: 'CascadingFiltersHeader',
  },
  {
    field: 'brandName',
    optionsPath: 'brand',
    headerName: 'Brand',
    type: 'CascadingFiltersSection',
  },
  {
    field: 'distributorCountryCode',
    optionsPath: 'distributorCountryCode',
    headerName: 'Country',
    type: 'CascadingFiltersHeader',
  },
  {
    field: 'modelYear',
    optionsPath: 'modelYear',
    headerName: 'Model Year',
    type: 'CascadingFiltersSection',
  },
  {
    field: 'originalModelName',
    optionsPath: 'models',
    headerName: 'Series',
    type: 'CascadingFiltersHeader',
  },
];

const DISTRIBUTOR_CODES = 'PageFilterExport.distributorCodes';

const formatToCodeNameName = ({ code, name }) => `${code} | ${name}`;

const setQuickFilters = ({ pageFilterData, quickFilters, storedFiltersGrouped }) => {
  const { quickFilters: storedQuickFilters } = storedFiltersGrouped;
  pageFilterData.PageFilterExport.quickFilters.forEach((quickFilter) => {
    let isChecked = false;
    if (storedQuickFilters) {
      const fieldObj = find(storedQuickFilters, {
        field: 'quickFilters',
        value: quickFilter.name,
      });
      isChecked = !!fieldObj?.isChecked;
    }

    const newQuickFilter = {
      displayName: formatDisplayName(quickFilter.name),
      value: quickFilter.name,
      field: 'quickFilters',
      headerName: 'Quick Filters',
      isChecked,
    };

    const isQuickFilter = !!find(newQuickFilter, quickFilters);
    if (!isQuickFilter) {
      quickFilters.push(newQuickFilter);
    }
  });
};

const setDistributors = ({ distributor, distributorArr, storedFiltersGrouped }) => {
  const { distributorCode } = storedFiltersGrouped;
  distributorArr?.forEach((distr) => {
    let isChecked = true;
    if (distributorCode) {
      const fieldObj = find(distributorCode, {
        field: 'distributorCode',
        value: distr.distributorCode,
      });
      isChecked = !!fieldObj?.isChecked;
    }

    const newDistributor = {
      displayName: formatDisplayName(distr.distributorName),
      value: distr.distributorCode,
      field: 'distributorCode',
      headerName: 'Distributor',
      isChecked,
    };
    const isDistributor = !!find(distributor, newDistributor);
    if (!isDistributor) {
      distributor.push(newDistributor);
    }
  });
};
const setBrands = ({
  distributor,
  brand,
  distributorArr,
  storedFiltersGrouped,
  isCascadingFilterOff,
}) => {
  distributorArr?.forEach((distr) => {
    const isParentDistributorChecked =
      isCascadingFilterOff ||
      !!find(distributor, {
        displayName: formatDisplayName(distr.distributorName),
        value: distr.distributorCode,
        isChecked: true,
      });
    if (isParentDistributorChecked && brand.length < 3) {
      distr.brandCodes.forEach((brandCode) => {
        const { brandName = false } = storedFiltersGrouped;
        let isChecked = true;
        if (brandName) {
          const fieldObj = find(brandName, {
            field: 'brandName',
            value: brandCode.brandCode,
          });
          if (fieldObj) {
            isChecked = fieldObj.isChecked;
          }
        }
        const newBrand = {
          displayName: formatDisplayName(brandCode.brandName),
          value: brandCode.brandCode,
          field: 'brandName',
          headerName: 'Brand',
          isChecked,
        };
        const isBrand = find(brand, newBrand);
        if (!isBrand) {
          brand.push(newBrand);
        }
      });
    }
  });
};
const setCountry = ({
  distributor,
  brand,
  distributorCountryCode,
  distributorArr,
  storedFiltersGrouped,
  isCascadingFilterOff,
}) => {
  distributorArr?.forEach((distr) => {
    const isParentDistributorChecked =
      isCascadingFilterOff ||
      !!find(distributor, {
        displayName: formatDisplayName(distr.distributorName),
        value: distr.distributorCode,
        isChecked: true,
      });
    if (isParentDistributorChecked) {
      distr.brandCodes.forEach((brandCode) => {
        const isParentBrandChecked =
          isCascadingFilterOff ||
          !!find(brand, {
            displayName: formatDisplayName(brandCode.brandName),
            value: brandCode.brandCode,
            isChecked: true,
          });

        if (isParentBrandChecked) {
          brandCode.countries.forEach((country) => {
            const storedCountryCode = storedFiltersGrouped?.distributorCountryCode;
            let isChecked = true;
            if (storedCountryCode) {
              const fieldObj = find(storedCountryCode, {
                field: 'distributorCountryCode',
                value: country.distributorCountryCode,
              });
              if (fieldObj) {
                isChecked = fieldObj.isChecked;
              }
            }
            const newCountry = {
              displayName: formatToCodeNameName({
                code: country.distributorCountryCode,
                name: country.countryName,
              }),
              value: country.distributorCountryCode,
              field: 'distributorCountryCode',
              headerName: 'Country',
              isChecked,
            };
            const isCountry = find(distributorCountryCode, newCountry);
            if (!isCountry) {
              distributorCountryCode.push(newCountry);
            }
          });
        }
      });
    }
  });
  sortAsc(distributorCountryCode);
};

const setModelYear = ({ pageFilterData, modelYear, storedFiltersGrouped }) => {
  const modelYearsOptions = get(pageFilterData, 'PageFilterExport.modelYears');

  modelYearsOptions?.forEach((year) => {
    let isChecked = true;
    const modelYears = storedFiltersGrouped.modelYear;
    if (modelYears) {
      isChecked = find(modelYears, {
        field: 'modelYear',
        value: year.value,
      })?.isChecked;
    }
    const newModelYear = {
      displayName: year.name,
      value: year.value,
      field: 'modelYear',
      headerName: 'Model Year',
      isChecked,
    };
    const isFleet = find(modelYear, newModelYear);
    if (!isFleet) {
      modelYear.push(newModelYear);
    }
  });
};

const setModels = ({
  distributor,
  brand,
  distributorCountryCode,
  distributorArr,
  storedFiltersGrouped,
  isCascadingFilterOff,
  models,
}) => {
  distributorArr?.forEach((distr) => {
    const isParentDistributorChecked =
      isCascadingFilterOff ||
      !!find(distributor, {
        displayName: formatDisplayName(distr.distributorName),
        value: distr.distributorCode,
        isChecked: true,
      });
    if (isParentDistributorChecked) {
      distr.brandCodes.forEach((brandCode) => {
        const isParentBrandChecked =
          isCascadingFilterOff ||
          !!find(brand, {
            displayName: formatDisplayName(brandCode.brandName),
            value: brandCode.brandCode,
            isChecked: true,
          });

        if (isParentBrandChecked) {
          brandCode.countries.forEach((country) => {
            const isParentCountryChecked =
              isCascadingFilterOff ||
              !!find(distributorCountryCode, {
                displayName: formatToCodeNameName({
                  code: country.distributorCountryCode,
                  name: country.countryName,
                }),
                value: country.distributorCountryCode,
                isChecked: true,
              });

            if (isParentCountryChecked) {
              country.modelNames.forEach((model) => {
                const storedModelNames = storedFiltersGrouped?.originalModelName;
                let isChecked = true;
                if (storedModelNames) {
                  const fieldObj = find(storedModelNames, {
                    field: 'originalModelName',
                    value: model.originalModelName,
                  });
                  if (fieldObj) {
                    isChecked = fieldObj.isChecked;
                  }
                }
                const newModel = {
                  displayName: formatDisplayName(model.originalModelName),
                  value: model.originalModelName,
                  field: 'originalModelName',
                  headerName: 'Models',
                  isChecked,
                };
                const isCountry = find(models, newModel);
                if (!isCountry) {
                  models.push(newModel);
                }
                sortAsc(models);
              });
            }
          });
        }
      });
    }
  });
};

const getFilterLists = ({ pageFilterData, storedFilters, isCascadingFilterOff }) => {
  const distributorArr = get(pageFilterData, DISTRIBUTOR_CODES);

  const parameters = {
    quickFilters: [],
    distributor: [],
    brand: [],
    distributorCountryCode: [],
    modelYear: [],
    models: [],
    distributorArr,
    storedFiltersGrouped: groupBy(storedFilters, 'field'),
    pageFilterData,
    isCascadingFilterOff,
  };

  setQuickFilters(parameters);
  setDistributors(parameters);
  setBrands(parameters);
  setCountry(parameters);
  setModelYear(parameters);
  setModels(parameters);

  return cascadingFiltersConfig.map(({ field, optionsPath, headerName, type }) => ({
    field,
    options: get(parameters, optionsPath),
    headerName,
    type,
  }));
};

const addAllDistributors = ({ pageFilterData, cascadingFiltersInitial }) => {
  const isDistributorInCascadingFilters = !!find(cascadingFiltersInitial, {
    field: 'distributorCode',
  });
  const distributors = get(pageFilterData, 'PageFilter.distributorCodes');
  const cascadingFiltersInitialClone = [...cascadingFiltersInitial];

  if (!isDistributorInCascadingFilters) {
    if (distributors) {
      distributors.forEach(({ distributorCode }) => {
        cascadingFiltersInitialClone.push({
          field: 'distributorCode',
          value: distributorCode,
          isChecked: true,
        });
      });
    }
  } else {
    cascadingFiltersInitial.forEach((cascadingFilter, index) => {
      if (
        cascadingFilter.field === 'distributorCode' &&
        !find(distributors, { distributorCode: cascadingFilter.distributorCode })
      ) {
        cascadingFiltersInitialClone.splice(index, 1);
      }
    });
  }
  return cascadingFiltersInitialClone;
};

const getCascadingFiltersInitial = ({
  cascadingFiltersLocalRef,
  cascadingFiltersLocal,
  cascadingFilters,
  setIsCascadingFilterOff,
  pageFilterData,
}) => {
  let cascadingFiltersInitial;
  if (!isEmpty(cascadingFiltersLocalRef.current)) {
    cascadingFiltersInitial = cascadingFiltersLocalRef.current.cascadingFiltersLocal;
    cascadingFiltersLocalRef.current.isCascadingFilterOff && setIsCascadingFilterOff(true);
  } else {
    cascadingFiltersInitial = isEmpty(cascadingFiltersLocal)
      ? cascadingFilters
      : cascadingFiltersLocal;
  }
  return addAllDistributors({ pageFilterData, cascadingFiltersInitial });
};

const CascadingFiltersOtherDistributorsView = ({ cascadingFiltersLocalRef, setPage }) => {
  const { selectedUserProfile } = useProfileContext();
  const {
    cascadingFilters,
    data: pageFilterData,
    setCascadingFilters,
  } = useCascadingFilterContext();

  const [cascadingOptions, setCascadingOptions] = useState([]);
  const [cascadingFiltersLocal, setCascadingFiltersLocal] = useState([]);
  const [isCascadingFilterOff, setIsCascadingFilterOff] = useState(false);

  useEffect(() => {
    if (pageFilterData) {
      const cascadingFiltersInitial = getCascadingFiltersInitial({
        cascadingFiltersLocalRef,
        cascadingFiltersLocal,
        cascadingFilters,
        setIsCascadingFilterOff,
        pageFilterData,
      });

      setInitialCascadingFilters({ cascadingFiltersInitial });
      setCascadingFiltersLocal(cascadingFiltersInitial);
    }
  }, [pageFilterData]);

  useEffect(() => {
    if (pageFilterData) {
      cascadingFiltersLocalRef.current = { cascadingFiltersLocal, isCascadingFilterOff };
      setCascadingOptions(
        getFilterLists({
          pageFilterData,
          storedFilters: cascadingFiltersLocal,
          isCascadingFilterOff,
        }),
      );
    }
  }, [cascadingFiltersLocal, isCascadingFilterOff, selectedUserProfile]);

  const hasUncheckedSection = () =>
    cascadingOptions.some((cascadingFilter) => {
      if (cascadingFilter.field === 'quickFilters') {
        return;
      }

      if (cascadingFilter.options && cascadingFilter.options.length > 0) {
        const numOptions = cascadingFilter.options.length;
        let unCheckedOptions = 0;

        cascadingFilter.options.forEach((option) => {
          if (!option.isChecked) {
            unCheckedOptions++;
          }
        });

        return numOptions === unCheckedOptions;
      }
    });

  useEffect(() => {
    if (pageFilterData) {
      setCascadingFiltersLocal(cascadingFilters);
    }
  }, [cascadingFilters]);

  const setInitialCascadingFilters = ({ cascadingFiltersInitial }) =>
    setCascadingOptions(
      getFilterLists({
        pageFilterData,
        storedFilters: cascadingFiltersInitial,
        isCascadingFilterOff,
      }),
    );

  const getListOptions = ({ headerName }) => {
    const { field } = find(cascadingFiltersConfig, { headerName });
    const { options } = find(cascadingOptions, { field });
    return options || [];
  };

  const applyFiltersHandler = ({ newCascadingFiltersLocal }) => {
    setPage(0);
    let filters = [];
    const groupedCascadingFiltersLocal = groupBy(
      newCascadingFiltersLocal || cascadingFiltersLocal,
      'field',
    );

    forOwn(groupedCascadingFiltersLocal, (value, field) => {
      let options;
      options = find(cascadingOptions, { field }).options;

      const valueIsChecked = filter(value, { isChecked: true });

      if (options.length !== valueIsChecked.length) {
        const filterOptions = options.map(({ field, value, isChecked }) => ({
          field,
          value,
          isChecked,
        }));
        filters = [...filters, ...filterOptions];
      }
    });

    setCascadingFilters(filters);
  };

  const getChipLabel = ({ headerName, options }) => getLabelForChip({ headerName, options });

  return (
    <CascadingFilters
      applyFiltersHandler={applyFiltersHandler}
      cascadingFiltersConfig={cascadingFiltersConfig}
      cascadingFiltersLocal={cascadingFiltersLocal}
      cascadingFiltersLocalRef={cascadingFiltersLocalRef}
      cascadingOptions={cascadingOptions}
      getCascadingFiltersInitial={getCascadingFiltersInitial}
      getChipLabel={getChipLabel}
      getFilterLists={getFilterLists}
      getListOptions={getListOptions}
      hasUncheckedSection={hasUncheckedSection}
      isCascadingFilterOff={isCascadingFilterOff}
      setCascadingFiltersLocal={setCascadingFiltersLocal}
      setCascadingOptions={setCascadingOptions}
      setIsCascadingFilterOff={setIsCascadingFilterOff}
    />
  );
};

CascadingFiltersOtherDistributorsView.propTypes = {
  cascadingFiltersLocalRef: PropTypes.object,
  setPage: PropTypes.func,
};

export default withErrorBoundary(CascadingFiltersOtherDistributorsView, {
  fallback: <AlertMessage />,
});
