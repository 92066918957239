import {
  compact,
  forOwn,
  has,
  isArray,
  isBoolean,
  isEmpty,
  isObject,
  isString,
  size,
} from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const DELETED_TABLE_HEIGHT_MAPPING = {
  byDeletions: {
    mobile: 'calc(100vh - 26.3rem)',
    default: 'calc(100vh - 22.8rem)',
  },
};

export const cascadingFiltersConfig = [
  { field: 'quickFilters', headerName: 'Quick Filter', default: false },
  { field: 'distributorCode', headerName: 'Distributor', default: true },
  { field: 'brandName', headerName: 'Brand', default: true },
  { field: 'fleetIndicator', headerName: 'Fleet', default: true },
  { field: 'currentRegionCode', headerName: 'Region', default: true },
  { field: 'currentDealerCode', headerName: 'Dealer', default: true },
  { field: 'modelYear', headerName: 'Model Year', default: true },
  { field: 'salesSeriesName', headerName: 'Series', default: true },
];

const cascadingFilterFlatMap = {
  distributorCode: {
    comparator: 'distributorCode',
    path: ['distributorCodes'],
    values: ['distributorName'],
  },
  currentRegionCode: {
    comparator: 'regionCode',
    path: ['distributorCodes', 'brandCodes', 'regions'],
    values: ['regionCode', 'regionName'],
  },
  currentDealerCode: {
    comparator: 'dealerCode',
    path: ['distributorCodes', 'brandCodes', 'regions', 'dealers'],
    values: ['dealerCode', 'dealerName'],
  },
  distributorCountryCode: {
    comparator: 'distributorCountryCode',
    path: ['distributorCodes', 'brandCodes', 'countries'],
    values: ['distributorCountryCode', 'countryName'],
  },
};

// Convert boolean value to string Yes or No
export const booleanToYN = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (value === 'true') {
    return 'Yes';
  }
  if (value === 'false') {
    return 'No';
  }
  if (value === 'Y') {
    return 'Yes';
  }
  if (value === 'N') {
    return 'No';
  }

  return value;
};

// Format filter chip label
export const formatChipFilterLabel = (filter) => {
  if (filter.value === null) {
    return 'NULL';
  }
  if (filter.value !== null && !isBoolean(filter.value) && !filter.value) {
    return 'NULL';
  }

  if (isArray(filter.value) && filter.value.length) {
    //requirement from business to separate date using "-"" instead of ","
    if (['unitAdditionDate', 'unitDeletionDate'].includes(filter.field)) {
      return filter.value.map((value) => value?.toString().toUpperCase()).join(' - ');
    }

    switch (filter.value.length) {
      case 1: {
        const value = filter.value[0];
        return filter?.filterType === 'boolean' || typeof value === 'boolean'
          ? booleanToYN(value)?.toString().toUpperCase()
          : value?.toString().toUpperCase();
      }
      case 2:
        return filter.value.map((value) => value?.toString().toUpperCase()).join(', ');
      default:
        return `${filter.value.length} Selected`;
    }
  }

  switch (filter.field) {
    case 'qualityHoldIndicator':
      return filter.value === true || filter.value === 'true'
        ? 'QUALITY COMPLIANCE HOLD'
        : 'NO QUALITY COMPLIANCE HOLD';
    case 'damageHoldIndicator':
      return filter.value === true || filter.value === 'true' ? 'DAMAGE HOLD' : 'NO DAMAGE HOLD';
    case 'holdIndicator':
      return filter.value === true || filter.value === 'true' ? 'VEHICLE HOLD' : 'NO VEHICLE HOLD';
    case 'otherHoldIndicator':
      return filter.value === true || filter.value === 'true' ? 'OTHER HOLD' : 'NO OTHER HOLD';
    case 'financeHoldIndicator':
      return filter.value === true || filter.value === 'true' ? 'FINANCE HOLD' : 'NO FINANCE HOLD';
    case 'fleetIndicator':
      return filter.value === true || filter.value === 'true' ? 'FLEET' : 'NON-FLEET';
    case 'soldOrderIndicator':
      return filter.value === true || filter.value === 'true' ? 'SOLD ORDER' : 'NOT SOLD';
    case 'reservedComputedIndicator':
    case 'reservedIndicator':
      return filter.value === true || filter.value === 'true' ? 'RESERVED' : 'NOT RESERVED';
    case 'presoldComputedIndicator':
    case 'presoldIndicator':
      return filter.value === true || filter.value === 'true' ? 'PRESOLD' : 'NOT PRESOLD';
    case 'unpricedIndicator':
      return filter.value === true || filter.value === 'true' ? 'UNPRICED' : 'PRICED';
    case 'pipelineProtectionIndicator':
      return filter.value === true || filter.value === 'true'
        ? 'PIPELINE PROTECTION'
        : 'NO PIPELINE PROTECTION';
    case 'soldOrderVipDealerSelectedIndicator':
      return (filter.value === true || filter.value === 'true') && 'DEALER';
    case 'soldOrderVipHqSelectedIndicator':
      return (filter.value === true || filter.value === 'true') && 'NATIONAL';
    case 'soldOrderVipRegionSelectedIndicator':
      return (filter.value === true || filter.value === 'true') && 'REGION';
    case 'spoDonorIndicator':
      return filter.value === true || filter.value === 'true' ? 'SPO DONOR' : 'NO SPO DONOR';
    case 'carTruckCode': {
      if (filter.value === 'C') {
        return 'CAR';
      }
      if (filter.value === 'T') {
        return 'TRUCK';
      }
      return filter.value;
    }
    case 'vipRequestIndicator':
      return (filter.value === true || filter.value === 'true') && 'VIP UNITS';
    case 'vehicleLifecycleStatus':
      return filter.value === 'Company Stock' && filter.operator === 'ne'
        ? 'NOT COMPANY STOCK'
        : filter.value.toUpperCase();
    case 'customerRequestTypeIndicator':
      return (filter.value === true || filter.value === 'true') && 'CUSTOMER REQUEST UNITS';
    case 'retailSalesBusinessSalesMonth':
    case 'exportDealerInvoiceDate':
    case 'exportDealerInvoiceBusinessSalesMonth':
      return filter.value
        ? dayjs(filter.value.toString()).format('MM/YYYY')
        : filter.value?.toString().toUpperCase();
    default: {
      return filter?.filterType === 'boolean' || typeof filter.value === 'boolean'
        ? booleanToYN(filter.value)?.toString().toUpperCase()
        : filter.value?.toString().toUpperCase();
    }
  }
};

// Format phone number
export const phoneNumberFormatting = (value) => {
  const cleaned = value?.toString();

  if (cleaned?.length === 10) {
    const area = cleaned.substring(0, 3);
    const prefix = cleaned.substring(3, 6);
    const line = cleaned.substring(6, 10);
    return `(${area}) ${prefix}-${line}`;
  }

  return value;
};

// Current Formatting
export const currencyFormatting = (value, isMexicanDistributor) => {
  if (typeof value === 'number' && !isNaN(value)) {
    const formattedValue = isMexicanDistributor
      ? new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value)
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
    return isMexicanDistributor ? `MXN$ ${formattedValue}` : formattedValue;
  }

  return value;
};

// Deep copy helper
export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

// Get amount for sales tables
export const getSaleAmount = (value, isCredit, isMexicanDistributor) => {
  if (!value) {
    return currencyFormatting(0, isMexicanDistributor);
  }
  if (typeof value === 'number' && !isNaN(value) && isCredit) {
    return `- ${currencyFormatting(value, isMexicanDistributor)}`;
  }
  if (typeof value === 'number' && !isNaN(value) && !isCredit) {
    return currencyFormatting(value, isMexicanDistributor);
  }
};

// Checking is Vehicle Details data exist
export const isVehicleDetailsDataExist = (dataFieldsArray) => size(compact(dataFieldsArray)) > 0;

export const truncateText = ({ text, maxLength }) =>
  text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

export const formatCapitalization = (inputString) =>
  inputString.toLowerCase().replace(/\b(?:tms|hq|pii|[a-zA-Z])\w*\b/g, (match) =>
    // Capitalize 'tms' and 'hq', and capitalize other words
    match === 'tmna' || match === 'tms' || match === 'hq' || match === 'pii'
      ? match.toUpperCase()
      : match.charAt(0).toUpperCase() + match.slice(1),
  );

export const removeInactiveFilters = (filters) => {
  if (!filters?.filter) {
    return filters;
  }
  const operator = Object.keys(filters.filter)[0];
  const filtersCopy = deepCopy(filters);
  const activeFilters = filtersCopy.filter[operator]
    .filter((filterItem) => !filterItem.inactive)
    .map((activeFilterItem) => {
      delete activeFilterItem.inactive;
      return activeFilterItem;
    });
  filtersCopy.filter[operator] = activeFilters;
  return filtersCopy.filter;
};

export const removeInactiveAndAddRequiredFilters = (filters, requiredFilters) => {
  const activeFilters = removeInactiveFilters(filters);
  if (!requiredFilters) {
    return activeFilters;
  }

  if (!activeFilters || !Object.keys(activeFilters).length) {
    return { and: [requiredFilters] };
  }

  return { and: [activeFilters, requiredFilters] };
};

export const formatTimeZone = (dayjsObject) =>
  dayjsObject
    .tz(dayjs.tz.guess())
    .format('zzz')
    .split(' ')
    .map((item) => item[0])
    .join('');

export const formatUtcStringToLocal = (utcString, format = 'MMM DD, YYYY') =>
  utcString ? dayjs.utc(utcString).local().format(format) : '';

export const isUtcStringLessThanHours = (utcString, hours) => {
  if (!utcString) {
    return false;
  }
  const localDateNow = dayjs();
  const localDateToCompare = dayjs.utc(utcString).local();
  const diff = localDateNow.diff(localDateToCompare, 'hour');

  return diff <= hours && diff >= 0;
};

export const pluralize = (count, original, pluralized) => (count > 1 ? pluralized : original);

export const getTextWidth = (text, font = '14px Toyota Type') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
};

export const splitArrayIntoNChunks = (arr, n) => {
  const splitArrays = [];
  for (let i = 0; i < arr.length; i += n) {
    splitArrays.push(arr.slice(i, i + n));
  }
  return splitArrays;
};

export const splitArrayWithSubRowsIntoNChunks = (data, chunkSize) => {
  const result = [];
  let currentChunk = [];
  let currentSize = 0;

  for (const item of data) {
    const itemBaseSize = 1; // Size of the parent object
    const subItems = [...item.subItems];

    while (subItems.length > 0) {
      // Calculate remaining space in the current chunk
      const remainingSpace = chunkSize - currentSize - itemBaseSize;

      if (remainingSpace >= 0) {
        // Add parent object if it's not already in the current chunk
        if (currentChunk.findIndex((i) => i.id === item.id) === -1) {
          currentChunk.push({ ...item, subItems: [] });
          currentSize += itemBaseSize;
        }

        // Determine how many subitems can be added to the current chunk
        const subItemsToAdd = subItems.splice(0, remainingSpace);
        const parentIndex = currentChunk.findIndex((i) => i.id === item.id);
        currentChunk[parentIndex].subItems.push(...subItemsToAdd);
        currentSize += subItemsToAdd.length;

        // If the current chunk is full, push it to the result and start a new chunk
        if (currentSize >= chunkSize) {
          result.push(currentChunk);
          currentChunk = [];
          currentSize = 0;
        }
      } else {
        // If there is no room for the parent object in the current chunk, push the chunk and start a new one
        result.push(currentChunk);
        currentChunk = [];
        currentSize = 0;
      }
    }
  }

  // Add the last chunk if it has any items
  if (currentChunk.length > 0) {
    result.push(currentChunk);
  }

  return result;
};

export const findPropertyByName = (obj, key) => {
  let result;
  const search = (obj) => {
    if (result !== undefined) {
      return;
    }
    if (has(obj, key) && isString(obj[key])) {
      result = obj[key];
      return;
    }
    forOwn(obj, (value) => {
      if (isObject(value)) {
        search(value);
      }
    });
  };
  search(obj);
  return result;
};

export const traverseData = (data, item, path, pathConfig) => {
  // If it's the last path, then find the value
  if (path?.length === 1) {
    const value = data[path[0]]?.find((dataItem) => dataItem[pathConfig.comparator] === item.value);

    // If there are multiple values to use, then join them by | after filtering out the undefined/null/empty values
    if (value) {
      return pathConfig.values
        .map((confValue) => (value[confValue] ? value[confValue] : ''))
        .filter(Boolean)
        .join(' | ');
    }

    return null;
  } else if (path?.length > 1) {
    const [currentLevel, ...remainingPath] = path;
    if (!data[currentLevel]) {
      return null;
    }

    // Go one level deeper recursively by following the path from config provided
    for (const subData of data[currentLevel]) {
      const found = traverseData(subData, item, remainingPath, pathConfig);

      if (found) {
        return found;
      }
    }
  }

  return null;
};

export const getCascadingValue = (item, data) => {
  const finalValue = item.value;
  if (!data) {
    return finalValue;
  }

  const pathConfig = cascadingFilterFlatMap[item.field];

  if (!pathConfig) {
    return finalValue;
  }

  const { path, ...restConfig } = pathConfig;

  return traverseData(data, item, path, restConfig);
};

export const transformFilters = (filters, data) =>
  filters?.reduce((acc, item) => {
    if (item.isChecked) {
      if (!acc[item.field]) {
        acc[item.field] = [];
      }
      acc[item.field].push(getCascadingValue(item, data));
    }
    return acc;
  }, {});

export const getAlleyFilters = ({ cascadingFiltersConfig, filters = [], data }) => {
  const formattedFilters = transformFilters(filters, data);
  return cascadingFiltersConfig
    .filter((filter) => formattedFilters[filter.field] || !filter.removeFromFilterAlly)
    .map((filter) => {
      if (filter.field === 'quickFilters') {
        return formattedFilters.quickFilters.map((value) => ({
          label: filter.headerName,
          value: [value],
        }));
      }
      return {
        label: filter.headerName,
        value: formattedFilters[filter.field] || ['ALL'],
      };
    })
    .flat();
};

const isEmptyObjectOrArray = (value) => {
  if (isObject(value) || isArray(value)) {
    return isEmpty(value);
  }
  return false;
};
export const cleanObject = (item) => {
  if (isArray(item)) {
    // Map and filter out any empty or undefined values
    const cleanedArray = item
      .map(cleanObject)
      .filter((filterItem) => filterItem !== undefined && !isEmptyObjectOrArray(filterItem));
    return cleanedArray.length > 0 ? cleanedArray : []; // Return empty array if cleanedArray is empty
  }
  if (isObject(item)) {
    const cleaned = {};
    Object.keys(item).forEach((key) => {
      const cleanedValue = cleanObject(item[key]);
      if (cleanedValue !== undefined && !isEmptyObjectOrArray(cleanedValue)) {
        cleaned[key] = cleanedValue;
      }
    });
    return Object.keys(cleaned).length > 0 ? cleaned : {};
  }
  return item;
};

export const isChipTooltipEnabled = (value) => isArray(value) && value?.length > 2;

export const getChipTooltipText = (value) => {
  if (!isArray(value)) {
    return null;
  }

  if (value.length > 5) {
    return [...value.slice(0, 5), `+ ${value.length - 5} more`].join(',\n');
  }

  return value.join(', ');
};

export const getTableHeight = ({
  expandedRowCount,
  tableData,
  selectedTable,
  expandedRows,
  isMobile,
}) => {
  const deletedTableHightMapping = DELETED_TABLE_HEIGHT_MAPPING[selectedTable.id];

  const height = isMobile
    ? deletedTableHightMapping?.mobile || 'calc(100vh - 26.8rem)'
    : deletedTableHightMapping?.default || 'calc(100vh - 23.3rem)';

  if (tableData?.data?.length === 0) {
    return '12.3255rem';
  }

  if (tableData?.data?.length < 25) {
    const HEADER_HEIGHT = 5.625;

    const expandedRowNum = expandedRowCount({
      tableId: selectedTable.id,
      expandedRows,
      tableData: tableData?.data,
    });
    const tableDataHeight = (size(tableData?.data) || 0) * 3;
    const expandedRowHeight = expandedRowNum * 3;
    const tableHeight = (tableDataHeight + expandedRowHeight) * 16;
    const isTableHeightExceeded = tableHeight > window.innerHeight - 19 * 16;
    const totalsRowHeight = tableData?.totals ? 3 : 0;

    return isTableHeightExceeded
      ? height
      : `${tableDataHeight + expandedRowHeight + HEADER_HEIGHT + totalsRowHeight}rem`;
  }

  return height;
};

export const getBookmarkPayload = (rowSelectionModel) =>
  Object.values(
    rowSelectionModel
      .map((item) => Object.values(item)[0])
      .filter(({ modelYear, urn }) => modelYear && urn)
      .reduce((acc, { modelYear, urn }) => {
        acc[modelYear] = acc[modelYear]
          ? { modelYear, urns: [...acc[modelYear].urns, urn] }
          : { modelYear, urns: [urn] };
        return acc;
      }, {}),
  );

// Group by urns by modelYear
export const groupBookmarksData = (bookmarksData) => {
  // Initialize a Map to store grouped data by modelYear
  const grouped = new Map();

  for (let i = 0; i < bookmarksData.length; i++) {
    const { modelYear, urn } = bookmarksData[i];

    if (modelYear && urn) {
      // If the Map doesn't already have an entry for this modelYear, create a new entry with modelYear and an empty urns array
      if (!grouped.has(modelYear)) {
        grouped.set(modelYear, { modelYear, urns: [] });
      }

      // Add the urn to the existing array for this modelYear
      grouped.get(modelYear).urns.push(urn);
    }
  }

  // Convert the Map's values to an array and return it
  return Array.from(grouped.values());
};

export const getIsDuplicateInput = (inputValue, existingValues = []) =>
  !!existingValues.find((item) => item.toLowerCase() === inputValue.trim().toLowerCase());
